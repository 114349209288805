import logo from './logo.svg';
import 'typeface-montserrat';
import './index.css';
import './App.css';
import item1 from './img/capturaCapeer.png';
import item2 from './img/CapturaConcertSearch.png';
import item3 from './img/building.png';
import orioffLogo from './img/OriOFFLogo1.jpg';
import linkedIn from './img/linkedinIcon.png';
import github from './img/githubLogo.png';
import User from "./classes/user.js";
import { useState } from 'react';
import Popup from "./components/Popup";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import SignUp from './components/SignUp.js';
import { useTranslation } from 'react-i18next';

// Crear un nuevo usuario
// const newUser = new User("unique-user-id", "user@example.com", "Juan Pérez", 30, "Argentina");

const scrollToSection = (sectionid) => {
  const section = document.getElementById(sectionid);
  section.scrollIntoView({ behavior: 'smooth' });
}

function App() {
  const { t, i18n } = useTranslation();

  const [isLogged, setIsLogged] = useState(true); // Debería ser false de primeras
  const [isOpen, setIsOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [notTranslated, setNotTranslated] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const togglePopupInfo = () => {
    setIsOpen(!isOpen);
  };  

  const togglePopupNotTranslated = () => {
    setNotTranslated(!notTranslated);
    setIsOpen(!isOpen);
  }; 

  const togglePopupSignIn = () => {
    setLogin(false);
    setIsOpen(!isOpen);
  }; 

  return (
    <div className="App">
      <header className="App-header">
      <div className='changeLang'>
        <button onClick={() => {changeLanguage('en'); togglePopupNotTranslated()}}>🇬🇧</button>
        <button onClick={() => {changeLanguage('es'); togglePopupNotTranslated()}}>🇪🇸</button>
      </div>
        <div id='ownnamediv' style={{gap: '1.5vw'}}>
          <p id='ownname'>Fabio Senande Torrado</p><p>✦</p>
          <button id='login' className='portadabutton' onClick={togglePopupSignIn}>{t('signin')}</button>
        </div>
         <div id='orioffdiv'>
          <div>
            <p className='orioffstudio' id='orioffTitle'>OriOFF</p>
            <p className='orioffstudio' id='studio'>Studio</p>
          </div>
          <div id='portadabuttons'>
            <button className='portadabutton' id='buynow' onClick={() => scrollToSection('items-section')}> {t('myapps')}</button>
            <button className='portadabutton' onClick={() => window.location.href = 'https://www.orioffstudio.com/about#me'}>{t('aboutme')}</button>
          </div>
        </div>       
      </header>
      <section className="section" id='items-section'>
        <div className='titleapps'>
          <p>Applications</p>
        </div>
        <div id='items'>
          <button className='item' onClick={() => window.location.href = 'https://capeer.orioffstudio.com'} aria-label="Go to Project Management App">
            <img className='itemimage' alt="Concert Search Icon" src={item1}></img>
            <h3 className='itemtitle'>Project Management App</h3>
            <p className='itemdesc'>Manage your organizations, projects and reviews</p>
          </button>
          <button className='item' onClick={() => window.location.href = 'https://music.orioffstudio.com'} aria-label="Go to Concert Finder App">
            <img className='itemimage' src={item2}></img>
            <h3 className='itemtitle'>Concert Finder App</h3>
            <p className='itemdesc'>Find concerts from your favorite artists</p>
          </button>
          <button className='item' onClick={() => scrollToSection('section3')}>
            <img className='itemimage' src={item3}></img>
            <h3 className='itemtitle'>Photo Competition App</h3>
            <p className='itemdesc'>Compete with your friends and showcase your photos</p>
          </button>
        </div>
        <div id='sectionbutton'>
          <button className='portadabutton' id='shopall' onClick={() => window.location.href = 'https://www.orioffstudio.com/about#orioff'}>About OriOFF</button>
        </div>
      </section>
      <section className="section" id='section1'>
        <div id='sectiontext'>
          <h3 className='sectiontitle'>Project Management App</h3>
          <p className='sectiondesc'>Manage your organizations, projects, tasks and reviews with ease. By linking your GitHub account, you can request code revisions and receive feedback from specialized members within your organizations and projects.</p>
        </div>
        <img className='sectionimage' onClick={() => window.location.href = 'https://capeer.orioffstudio.com'} src={item1}></img>
      </section>
      <section className="section" id='section2'>
        <img className='sectionimagerev' onClick={() => window.location.href = 'https://music.orioffstudio.com'} src={item2}></img>
        <div id='sectiontext'>
          <h3 className='sectiontitle'>Concert Search App</h3>
          <p className='sectiondesc'>Connect your Spotify account to discover concerts by your favorite artists and playlists. Plan your trip seamlessly with Skyscanner for flights and Booking for accommodations. More features are on the way!</p>
        </div>
      </section>
      <section className="section" id='section3'>
        <div id='sectiontext'>
          <h3 className='sectiontitle'>Photo Competition App</h3>
          <p className='sectiondesc'>Compete with your friends and showcase your photos. Currently in progress!</p>
        </div>
        <img className='sectionimage' src={item3}></img>
      </section>
      <footer className='footer'>
        <div className='footerContent'>
          
          <div className='footerCol' id='footerCol2'>
            <p className='footerTitle'>Personal info:</p>
            <p>fst2121@gmail.com</p>
          </div>
          <div className='footerCol' id='footerCol1'>
            <p className='footerTitle'>Projects by</p>
            <p>Fabio Senande Torrado</p>
          </div>
        </div>
        <div id='myCvFooter'>

          <a className="structureDiagramUrl" href="https://www.linkedin.com/in/fabiosenandetorrado/" rel="noopener noreferrer" target="_blank"><img className='structureDiagram' src={linkedIn} alt="LinkedIn Logo" /></a>
        
          <a className="structureDiagramUrl" id='githubCont' href="https://github.com/FabioSTO" rel="noopener noreferrer" target="_blank"><img id='githubLogo' className='structureDiagram' src={github} alt="GitHub Logo" /></a>
  
        </div>
      </footer>
      {login ? 
        <Popup isOpen={isOpen} onClose={togglePopupInfo} >
          <h3 style={{maxWidth: "60vw", color: "#ffb547"}}>
            Aquí va solo lo de FireBase, a lo mejor ni Popup le meto.
          </h3>

        </Popup> 
        
        : notTranslated ? 

        <Popup isOpen={isOpen} onClose={togglePopupNotTranslated} >
          <div className='form' id="signUpForm">
            <h3 style={{maxWidth: "60vw", color: "#ffb547"}}>
            Some parts are still in English. I'm working on it! 
            <br />
            Algunas partes están en inglés. ¡Estoy en ello!
            </h3>
          </div>
        </Popup>

        :
        
        <Popup isOpen={isOpen} onClose={togglePopupSignIn} >
          <SignUp />
        </Popup>
      }
      
    </div>
  );
}

export default App;
