import React, { useEffect, useState } from 'react';
import '../css/account.css';

const Account = () => {
  const [data, setData] = useState([]);
  const [cities, setCities] = useState([]);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [city, setCity] = useState("");
  const [nationality, setNationality] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [showedImage, setShowedImage] = useState(null);
  const [error, setError] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(file);
      setShowedImage(imageUrl);
    }
  };

  useEffect(() => {
    fetch('countries_with_cities.json')
      .then((response) => response.json())
      .then((data) => {
        setData(data)
      })
      .catch((error) => console.error('Error fetching countries', error))
  }, []);

  useEffect(() => {
    if (nationality) {
      setCities(data[nationality] || []);
    } else {
      setCities([]);
    }
  }, [nationality, data]);

  return (
    <div className="App">
    <section className="section" id='items-section'>
      <div className='titleapps'>
        <p id='createAnAccount'>Create an account</p>
      </div>
      <form className='form'>
        <label htmlFor='username' className='formLabel'>
          <span>Username</span>
          <span style={{ color: "red", marginLeft: "5px" }}>*</span>
        </label>
        <input
            id='username'
            value={username}
            className='formInput'
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <label htmlFor='name' className='formLabel'>
            <span>Name</span>
            <span style={{ color: "red", marginLeft: "5px" }}>*</span>
          </label>
          <input
            id="name"
            type="text"
            placeholder="(example: Juan Pérez)"
            value={name}
            className='formInput'
            onChange={(e) => setName(e.target.value)}
            required
          />
          <label htmlFor='username' className='formLabel'>
            <span>About me</span>
            <span style={{ color: "red", marginLeft: "5px" }}>*</span>
          </label>
          <textarea value={bio} className='formInput' id='bio' onChange={(e) => setBio(e.target.value)} required></textarea>
          
          <label htmlFor='username' className='formLabel'>
            <span>Profile picture</span>
            <span style={{ color: "red", marginLeft: "5px" }}>*</span>
          </label>

          <div className="image-uploader">
            <input
              type="file"
              accept="image/*"
              id="file-input"
              onChange={handleImageChange}
            />
            <label htmlFor="file-input" className="custom-file-upload">
            Choose File
            </label>
            {selectedImage && (
              <div className="image-preview">
                <img src={showedImage} alt="Selected" />
              </div>
            )}
          </div>

          <p style={{ color: "blueviolet", marginBottom:"25px", fontSize: "14px", fontWeight:"bold" }}>(Recommended for features like concert browsing)</p>
          
          <label htmlFor='nationality' className='formLabel'>
            <span>Nationality</span> 
            <span style={{ color: "blueviolet", marginLeft: "5px" }}>*</span>
          </label>
          <select value={nationality} className='formInput' id='nationality' onChange={(e) => setNationality(e.target.value)}>
            <option value="" disabled>
              Select your nationality
            </option>
            {Object.keys(data).map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
          <label htmlFor='city' className='formLabel'>
            <span>City</span>
            <span style={{ color: "blueviolet", marginLeft: "5px" }}>*</span>
          </label>
          <select value={city} className='formInput' id='city' onChange={(e) => setCity(e.target.value)} disabled={!nationality}>
            <option value="" disabled>
              Select city
            </option>
            {cities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
          <button className='portadabutton' id='formButton' type="submit" disabled>Create account</button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </section>
    <footer className='footer'>
      <p>Movidas de footer y eso</p>
      <p>correo@correo.com</p>
    </footer>
  </div>
  );
};

export default Account;