import React, {  useState } from 'react';
import firebase from '../img/firebase.png';
import aws from '../img/aws.png';
import cloudflare from '../img/cloudflare.png';
import orioffStructure from '../img/OriOFF.png';
import orioffLogo from '../img/OriOFFCardLogo-blueBack.png';
import linkedIn from '../img/linkedinIcon.png';
import github from '../img/githubLogo.png';
import '../css/about.css';
import Popup from './Popup';

const About = () => {
  const [isOpen, setIsOpen] = useState(null);

  const toggleContent = (sectionId) => {
    setIsOpen(isOpen === sectionId ? null : sectionId);
  };

  return (
    <div className="App">
      <section className="section" id='me'>
        <div className='titleAbout'>
          <h4 style={{fontWeight: "600", marginBottom: "3rem"}}>About me</h4>
          <div className='aboutGroup' id='aboutMeGroup'>
            <div id='myCv'>

              <a className="structureDiagramUrl" href="https://www.linkedin.com/in/fabiosenandetorrado/" rel="noopener noreferrer" target="_blank"><img className='structureDiagram' src={linkedIn} alt="LinkedIn Logo" /></a>
            
              <a className="structureDiagramUrl" id='githubCont' href="https://github.com/FabioSTO" rel="noopener noreferrer" target="_blank"><img id='githubLogo' className='structureDiagram' src={github} alt="GitHub Logo" /></a>
            
              <a className="structureDiagramUrl" href="https://www.orioffstudio.com" rel="noopener noreferrer" target="_blank"><img className='structureDiagram' src={orioffLogo} alt="OriOFF Logo" /></a>
              
            </div>
          </div>
        </div>
      </section>

      <section className="section" id='orioff'>
        <div className='titleAbout'>
          <h4 style={{fontWeight: "600", marginBottom: "1rem"}}>About OriOFF</h4>

          <div className='aboutGroupIntro'>
            <p className='aboutContent'>
              OriOFF Studio is my personal website, which serves as both my portfolio for showcasing apps and as an experiment to gain practical experience in managing Cloud Solutions.
            </p>
          </div>

          <a className="structureDiagramUrl" href={orioffStructure} rel="noopener noreferrer" target="_blank"><img className='structureDiagram' id='structureImage' src={orioffStructure} alt="System Architecture Diagram" /></a>

          <div className='aboutGroupIntro'>
            <p className='aboutContent' style={{marginTop: "0"}}>
              After researching and considering various options, I chose the most cost-effective 
              (and free, when possible) solutions. Here's a breakdown of the services I decided to use:
            </p>
          </div>

            <div className='aboutGroup'>
              <h4 className="serviceTitle"> <img className='cloudLogo' style={{marginRight: "1rem"}} src={aws} alt="AWS logo" />Amazon Web Services</h4>
              <p className='aboutContent'>
                I am using AWS under their 12-month Free Tier, which provides limited usage on select AWS
                products and services. These are the key services I use:
              </p>
              <ul className="serviceList">
                <li>Website Hosting: <span className='serviceName'>AWS S3</span> (Simple Storage Service)</li>
                <li>Web Server Hosting: <span className='serviceName'>AWS EC2</span> (Elastic Compute Cloud)</li>
                <li>CDN (Content Delivery Network): <span className='serviceName'>AWS CloudFront</span></li>
                <li>Database Hosting: <span className='serviceName'>AWS RDS</span> (Relational Database Service)</li>
                <li>Domain Register: <span className='serviceName'>AWS Route 53</span></li>
              </ul>

              <div onClick={() => toggleContent(1)} className="arrow-container">
                <span className={`arrow ${isOpen === 1 ? 'open' : ''}`}>&#9660;</span>

                <div className={`additionalInfo ${isOpen === 1 ? 'open' : ''}`}>
                <ul className="additionalInfoList">
                    <li style={{fontWeight: "500"}}>
                      AWS S3 buckets are used for hosting subdomain applications (frontend) and storing static files, including images and documents.
                    </li>
                    <li style={{fontWeight: "500"}}>
                      Currently, both apps are hosted on a single EC2 instance. While this setup is highly inefficient, it is constrained by AWS Free Tier's limit of one instance.
                    </li>
                  </ul>
                </div>
              </div>
              
            </div>

            <div className='aboutGroup'>
              <h4 className="serviceTitle"> <img className='cloudLogo' style={{marginRight: "0.3rem"}} src={firebase} alt="Firebase logo" />Firebase</h4>
              <p className='aboutContent'>
                Firebase offers a free plan (Plan Spark) with generous limits across many services:
                Here's what I use:
              </p>
              <ul className="serviceList">
                <li>Website Hosting: <span className='serviceName'>Firebase Hosting</span></li>
                <li>User Authentication: <span className='serviceName'>Firebase Authentication</span></li>
              </ul>

              <div onClick={() => toggleContent(2)} className="arrow-container">
                <span className={`arrow ${isOpen === 2 ? 'open' : ''}`}>&#9660;</span>

                <div className={`additionalInfo ${isOpen === 2 ? 'open' : ''}`}>
                  <p>
                    Firebase is used to host the main <a class="custom-link" href="https://www.orioffstudio.com">www.orioffstudio.com</a> website to take full advantage of its
                    Authentication system and Firestore (NoSQL cloud database).
                  </p>
                </div>
              </div>

            </div>

            <div className='aboutGroup'>
              <h4 className="serviceTitle"> <img className='cloudLogo' style={{marginRight: "0.6rem"}} src={cloudflare} alt="Cloudflare logo" />Cloudflare</h4>
              <p className='aboutContent'>
                I'm using Cloudflare's Free Plan for select services. A summary of the main services needed and the cloud options used to achieve them:
              </p>
              <ul className="serviceList">
                <li>DNS Management: <span className='serviceName'>Cloudflare DNS</span></li>
                <li>CDN: <span className='serviceName'>Cloudflare CDN</span></li>
                <li>Security: <span className='serviceName'>Cloudflare's DDoS Protection and Web Security</span></li>
              </ul>

              <div onClick={() => toggleContent(3)} className="arrow-container">
                <span className={`arrow ${isOpen === 3 ? 'open' : ''}`}>&#9660;</span>

                <div className={`additionalInfo ${isOpen === 3 ? 'open' : ''}`}>
                  <ul className="additionalInfoList">
                    <li style={{fontWeight: "500"}}>
                      Using AWS as a DNS Provider incurs in $0.50/month per hosted zone, $0.40 per million 
                      DNS queries and additional charges for WAF rules and ACLs. Cloudfare, on the other hand, offers DNS management, security, and 
                      protection for free. As a result, I replaced AWS DNS services with Cloudfare while keeping the domain registered in Route 53.
                    </li>
                    <li>
                      I considered 3 options for implementing a CDN:
                      <ul style={{marginTop: "0.6rem"}}>
                        <li><span style={{fontWeight: "600"}}>Only AWS Cloudfront CDN:</span> Cloudfront is tightly integrated with AWS infrastructure,  
                          particularly with S3. This allows restricting public access to the S3 bucket and granting access solely via the CloudFront URL, enhancing security. 
                          However, this approach risks exceeding the Free Tier limits.
                        </li>
                        <li><span style={{fontWeight: "600"}}>Only Cloudflare CDN:</span>Cloudflare allows proxying DNS records through its platform, 
                          acting as a DNS server. This setup  is ideal for maximizing Cloudflare's DDoS Protection and WAF. However, since Cloudflare operates outside AWS infrastructure, 
                          public access must be allowed to all Cloudflare URLs.
                        </li>
                        <li><span style={{fontWeight: "600"}}>Cloudflare + Cloudfront:</span>Combining both CDNs ensures S3 content is strictly restricted to the Cloudfront URL, 
                          while DNS security is maximized through Cloudflare. However, this approach might slightly impact user experience due to increased latency.
                        </li>
                      </ul>
                    </li>
                    <li>
                      I chose to combine CloudFront and Cloudflare to balance cost and security. This approach slightly impacts user experience, but in my experience, the 
                      effect is negligible and provides the best balance of security and affordability.
                    </li>
                  </ul>
                </div>
              </div>

            </div>
        </div>
      </section>
      <footer className='footer'>
        <div className='footerContent'>
          
          <div className='footerCol' id='footerCol2'>
            <p className='footerTitle'>Personal info:</p>
            <p>fst2121@gmail.com</p>
          </div>
          <div className='footerCol' id='footerCol1'>
            <p className='footerTitle'>Projects by</p>
            <p>Fabio Senande Torrado</p>
          </div>
        </div>
        <div id='myCvFooter'>

          <a className="structureDiagramUrl" href="https://www.linkedin.com/in/fabiosenandetorrado/" rel="noopener noreferrer" target="_blank"><img className='structureDiagram' src={linkedIn} alt="LinkedIn Logo" /></a>
        
          <a className="structureDiagramUrl" id='githubCont' href="https://github.com/FabioSTO" rel="noopener noreferrer" target="_blank"><img id='githubLogo' className='structureDiagram' src={github} alt="GitHub Logo" /></a>
  
        </div>
      </footer>

    </div>
  );
};

export default About;