import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          myapps: "My Apps",
          aboutme: "About me",
          signin: "Sign In"
        },
      },
      es: {
        translation: {
          myapps: "Mis Apps",
          aboutme: "Sobre mí",
          signin: "Iniciar Sesión"
        },
      },
    },
    fallbackLng: 'en',
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage', 'cookie'],
    },
    // lng: getLanguage(),  Añade esto para detectar el idioma SSSSSSSSSSSSSSSSSSSSSSSSSSSSSS
    interpolation: {
      escapeValue: false,
    },
  });

function getLanguage() {
  const detectedLanguage = navigator.language || navigator.userLanguage; // Detectar idioma del navegador
  return detectedLanguage.startsWith('es') ? 'es' : 'en'; // Si "es", usar español
}

  export default i18n;